<template>
  <div class="text-template-list">
    <v-container
      fluid
      grid-list-xl
    >
      <v-layout
        row
        wrap
      >
        <v-flex lg12>
          <Toolbar
            :handle-add="addHandler"
            :enable-refresh="true"
            :handle-refresh="refreshData"
            :title="$t('SeminarPackages')"
          />
          <v-data-table
            :headers="headers"
            :items="items"
            :items-per-page.sync="options.itemsPerPage"
            :loading="isLoading"
            :loading-text="$t('Loading...')"
            :options.sync="options"
            :server-items-length="totalItems"
            @update:options="onUpdateOptions"
            class="elevation-1"
            item-key="@id"
            v-model="selected"
          >
            <template
              slot="item.enabled"
              slot-scope="{ item }"
            >
              <v-icon v-if="item.enabled">mdi-check-circle</v-icon>
              <v-icon v-else>mdi-close-circle</v-icon>
            </template>
            <ActionCell
              :handle-delete="() => deleteHandler(props.item)"
              :handle-edit="() => editHandler(props.item)"
              slot="item.action"
              slot-scope="props"
            ></ActionCell>
          </v-data-table>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from "vuex";
  import { mapFields } from "vuex-map-fields";
  import ListMixin from "../../mixins/ListMixin";
  import ActionCell from "../../components/ActionCell";
  import Toolbar from "../../components/Toolbar";

  export default {
    name: "SeminarPackageList",
    servicePrefix: "SeminarPackage",
    mixins: [ListMixin],
    components: {
      Toolbar,
      ActionCell,
    },
    data() {
      return {
        headers: [
          { text: this.$i18n.t("name"), value: "name" },
          { text: this.$i18n.t("number"), value: "number" },
          {
            text: this.$i18n.t("Actions"),
            value: "action",
            sortable: false,
            align: "center",
          },
        ],
        selected: [],
        filters: {
          seminarPackage: {},
        },
        showSeminarPackageDialog: false,
        seminarPackageItem: {},
        showSeminarPackageDialogForm: false,
        seminarPackageFormItem: {},
        seminarPackageDialogTitle: this.$t("AddSeminarPackage"),
      };
    },
    computed: {
      ...mapGetters("seminarPackage", {
        items: "list",
      }),
      ...mapFields("seminarPackage", {
        deletedItem: "deleted",
        error: "error",
        isLoading: "isLoading",
        resetList: "resetList",
        totalItems: "totalItems",
        view: "view",
      }),
    },
    methods: {
      ...mapActions("seminarPackage", {
        getPage: "fetchAll",
        deleteItem: "del",
        clearSeminarPackageData: 'resetAllData',
      }),
      resetFilter() {
        this.filters = {
          profile: {},
        };
      },
      seminarPackageDialogControl(close = false) {
        this.showSeminarPackageDialogForm = !close;
      },
      handleCreated(item) {
        console.log(item);
      },
      handleUpdated(item) {
        console.log(item);
      },
      createOrEditSeminarPackageHandler(item) {
        this.seminarPackageFormItem = item ?? {};
        if (item) {
          this.seminarPackageDialogTitle = this.$t("EditSeminarPackage");
        } else {
          this.seminarPackageDialogTitle = this.$t("AddSeminarPackage");
        }
        this.seminarPackageDialogControl();
      },
      refreshData() {
        this.clearSeminarPackageData();
        this.onSendFilter();
      }
    },
  };
</script>
